import React from 'react';
import styles from '../styles/Journey.module.css'

const Journey = () => {
    return (

        <section className="bg-gradient-to-br from-red-600 to-yellow-500 p-16 mb-12 rounded-3xl shadow-2xl relative overflow-hidden">
    <div className="absolute inset-0 bg-pattern opacity-10"></div>

    <h2 className="text-6xl font-bold text-center text-white mb-8 relative z-10 font-serif">Your Wedding Journey with Us</h2>

    <p className="text-xl text-center mb-16 text-white relative z-10 max-w-3xl mx-auto">
        From the initial consultation to the final farewell, experience our comprehensive wedding planning services in a seamless journey.
    </p>

    <div className="relative z-10 max-w-6xl mx-auto">
        <div className="timeline">
            <div className="timeline-item left">
                <div className="content bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-xl p-8 text-white transition-all duration-300 hover:bg-opacity-30 hover:scale-105">
                    <div className="text-5xl font-bold mb-4 text-yellow-300">01</div>
                    <h3 className="text-2xl font-bold mb-4">Pre-Wedding Planning</h3>
                    <p className="text-lg">From venue scouting and engagement parties to creating custom invitations, we ensure everything is perfect before the big day.</p>
                </div>
            </div>
            <div className="timeline-item right">
                <div className="content bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-xl p-8 text-white transition-all duration-300 hover:bg-opacity-30 hover:scale-105">
                    <div className="text-5xl font-bold mb-4 text-yellow-300">02</div>
                    <h3 className="text-2xl font-bold mb-4">The Big Day</h3>
                    <p className="text-lg">On your wedding day, we manage everything from decor and catering to ceremonies and guest coordination, so you can enjoy every moment.</p>
                </div>
            </div>
            <div className="timeline-item left">
                <div className="content bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-xl p-8 text-white transition-all duration-300 hover:bg-opacity-30 hover:scale-105">
                    <div className="text-5xl font-bold mb-4 text-yellow-300">03</div>
                    <h3 className="text-2xl font-bold mb-4">Post-Wedding Bliss</h3>
                    <p className="text-lg">After the wedding, we assist with farewell brunches, post-wedding photography, and more to ensure a smooth transition to married life.</p>
                </div>
            </div>
        </div>
    </div>

    <div className="text-center mt-16 relative z-10">
        <button href="#contact"  className="bg-white text-red-600 hover:bg-yellow-100 font-bold py-3 px-8 rounded-full transition duration-300 ease-in-out transform hover:scale-105">
            Start Your Journey
        </button>
    </div>
</section>
     );
}

export default Journey;