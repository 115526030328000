import React from 'react'

const FAQ = () => {
    return ( <section id="faq" className="bg-white rounded-2xl shadow-2xl p-8 mb-12">
    <h2 className="text-4xl font-bold text-center mb-8 text-gray-800 font-serif">Frequently Asked Questions</h2>
    <div className="space-y-4">
        <details className="bg-gray-50 rounded-lg p-4">
            <summary className="font-bold cursor-pointer">How far in advance should I book your services?</summary>
            <p className="mt-2 text-gray-600">We recommend booking our services at least 9-12 months before your wedding date, especially for full planning services. However, we can accommodate shorter timelines depending on availability.</p>
        </details>
        <details className="bg-gray-50 rounded-lg p-4">
            <summary className="font-bold cursor-pointer">Do you handle destination weddings?</summary>
            <p className="mt-2 text-gray-600">Yes, we specialize in both local and destination Indian weddings. Our team has experience planning weddings across various locations in India and internationally.</p>
        </details>
        <details className="bg-gray-50 rounded-lg p-4">
            <summary className="font-bold cursor-pointer">Can you work with my budget?</summary>
            <p className="mt-2 text-gray-600">We work with a range of budgets and can tailor our services to meet your financial needs. During our initial consultation, we'll discuss your budget and create a plan that aligns with your vision and financial constraints.</p>
        </details>
        <details className="bg-gray-50 rounded-lg p-4">
            <summary className="font-bold cursor-pointer">What types of Indian weddings do you plan?</summary>
            <p className="mt-2 text-gray-600">We plan all types of Indian weddings, including Hindu, Sikh, Muslim, and fusion weddings. Our team is well-versed in various cultural traditions and can accommodate specific religious or regional customs.</p>
        </details>
    </div>
</section> );
}

export default FAQ;