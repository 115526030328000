import React from 'react'

const CompleteCare = () => {
    return(
        <section id="package" className="bg-gradient-to-r from-gold-light to-gold rounded-2xl shadow-2xl p-8 mb-12">
    <h2 className="text-4xl font-bold text-center mb-8 text-white font-serif">Complete Care Package</h2>
    <p className="text-lg text-center mb-12 text-white">
        Experience our all-inclusive wedding planning service that covers every aspect of your special day.
    </p>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        <div className="bg-white rounded-xl shadow-lg p-6 text-center transition duration-300 ease-in-out transform hover:scale-105">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 mx-auto text-gold mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8.25a4.5 4.5 0 00-4.5 4.5v4.5h9v-4.5a4.5 4.5 0 00-4.5-4.5zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
            </svg>
            <h3 className="text-2xl font-bold mb-2 text-gray-800">Personalized Planning</h3>
            <p className="text-gray-600">Custom planning and coordination from start to finish, tailored to your unique vision.</p>
        </div>
        <div className="bg-white rounded-xl shadow-lg p-6 text-center transition duration-300 ease-in-out transform hover:scale-105">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 mx-auto text-gold mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7v6a2 2 0 01-2 2H7a2 2 0 01-2-2V7m0-4v1a2 2 0 002-2h12a2 2 0 002 2V3m-2 4h2v4a2 2 0 01-2 2h-4v-6z"/>
            </svg>
            <h3 className="text-2xl font-bold mb-2 text-gray-800">Vendor Coordination</h3>
            <p className="text-gray-600">Seamless management of all vendors including catering, decor, and entertainment.</p>
        </div>

        <div className="bg-white rounded-xl shadow-lg p-6 text-center transition duration-300 ease-in-out transform hover:scale-105">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 mx-auto text-gold mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h14a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM8 2a2 2 0 00-2 2v1a2 2 0 00-2 2v1a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2V4a2 2 0 00-2-2H8z"/>
            </svg>
            <h3 className="text-2xl font-bold mb-2 text-gray-800">Full-Day Support</h3>
            <p className="text-gray-600">Dedicated support on your wedding day to ensure everything runs smoothly.</p>
        </div>

        <div className="bg-white rounded-xl shadow-lg p-6 text-center transition duration-300 ease-in-out transform hover:scale-105">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 mx-auto text-gold mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"/>
            </svg>
            <h3 className="text-2xl font-bold mb-2 text-gray-800">Logistics Management</h3>
            <p className="text-gray-600">Detailed planning and management of transportation, accommodation, and scheduling.</p>
        </div>
    </div>
    <div className="text-center mt-12">
        <button href="#contact" className="bg-white text-gold hover:bg-gray-100 font-bold py-3 px-8 rounded-full transition duration-300 ease-in-out transform hover:scale-105">
            Get More Information
        </button>
    </div>
</section>



    );
}

export default CompleteCare