import React from 'react'


const WhyToChoose = () => {
    return (

        <section className="bg-gray-100 rounded-2xl shadow-2xl p-8 mb-12">
        <h2 className="text-4xl font-bold text-center mb-8 text-gray-800 font-serif">Why Choose Us</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="bg-white rounded-xl shadow-lg p-6 text-center transition duration-300 ease-in-out transform hover:scale-105">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto text-gold mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
                <h3 className="text-2xl font-bold mb-2 text-gray-800">Experience</h3>
                <p className="text-gray-600">Years of expertise in planning and executing stunning Indian weddings.</p>
            </div>
            <div className="bg-white rounded-xl shadow-lg p-6 text-center transition duration-300 ease-in-out transform hover:scale-105">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto text-gold mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
                </svg>
                <h3 className="text-2xl font-bold mb-2 text-gray-800">Customization</h3>
                <p className="text-gray-600">Tailored services to match your unique vision and preferences.</p>
            </div>
            <div className="bg-white rounded-xl shadow-lg p-6 text-center transition duration-300 ease-in-out transform hover:scale-105">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto text-gold mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"/>
                </svg>
                <h3 className="text-2xl font-bold mb-2 text-gray-800">Vendor Network</h3>
                <p className="text-gray-600">Access to the best vendors and venues in the industry.</p>
            </div>
            <div className="bg-white rounded-xl shadow-lg p-6 text-center transition duration-300 ease-in-out transform hover:scale-105">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto text-gold mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
                <h3 className="text-2xl font-bold mb-2 text-gray-800">Budget Management</h3>
                <p className="text-gray-600">Expert allocation and management of your wedding budget.</p>
            </div>
        </div>
    </section>
     );
}

export default WhyToChoose;