import React from 'react'

const Feedback = () => {
    return (  <section id="testimonials" className="bg-white rounded-2xl shadow-2xl p-8 mb-12">
    <h2 className="text-4xl font-bold text-center mb-8 text-gray-800 font-serif">What Our Clients Say</h2>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="bg-gray-50 rounded-xl p-6 shadow-lg">
            <p className="text-gray-600 mb-4">"Elegant Indian Weddings made our dream wedding a reality. Their attention to detail and cultural understanding was impressive."</p>
            <p className="font-bold">- Priya & Rahul</p>
        </div>
        <div className="bg-gray-50 rounded-xl p-6 shadow-lg">
            <p className="text-gray-600 mb-4">"We couldn't have asked for a better team to plan our destination wedding. Every moment was perfect!"</p>
            <p className="font-bold">- Anita & Vikram</p>
        </div>
    </div>
</section> );
}

export default Feedback;