import React from 'react'
import haldiImage from '../assets/haldi.jpg';


const Culture = () =>{
  return (
    <section className="cultural-expertise-section bg-gradient-to-br from-red-600 to-yellow-500 p-16 mb-12 rounded-3xl shadow-2xl relative overflow-hidden">
    <div className="absolute inset-0 bg-pattern opacity-10"></div>

    <h2 className="text-6xl font-bold text-center text-white mb-12 relative z-10">Our Cultural Expertise</h2>

    <div className="max-w-7xl mx-auto">
        <div className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-xl p-8 text-white mb-12">
            <p className="text-xl leading-relaxed">
                At Elegant Indian Weddings, we pride ourselves on our deep understanding and respect for the rich tapestry of Indian wedding traditions. Our expertise spans across various cultural rituals, ensuring that every aspect of your wedding is authentic, meaningful, and beautifully executed.
            </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="ceremony-card group">
                <div className="bg-white rounded-xl shadow-xl overflow-hidden transform transition-all duration-300 group-hover:scale-105">
                    <img src={haldiImage}  alt="Haldi Ceremony" className="w-full h-48 object-cover"/>
                    <div className="p-6">
                        <h3 className="text-2xl font-semibold text-gray-800 mb-2">Haldi Ceremony</h3>
                        <p className="text-gray-600">A colorful and joyful pre-wedding ritual where turmeric paste is applied to the couple.</p>
                    </div>
                </div>
            </div>

            <div className="ceremony-card group">
                <div className="bg-white rounded-xl shadow-xl overflow-hidden transform transition-all duration-300 group-hover:scale-105">
                    <img src="https://via.placeholder.com/400x300.png?text=Mehendi" alt="Mehendi" className="w-full h-48 object-cover"/>
                    <div className="p-6">
                        <h3 className="text-2xl font-semibold text-gray-800 mb-2">Mehendi</h3>
                        <p className="text-gray-600">An intricate henna application ritual celebrated with music and dance.</p>
                    </div>
                </div>
            </div>

            <div className="ceremony-card group">
                <div className="bg-white rounded-xl shadow-xl overflow-hidden transform transition-all duration-300 group-hover:scale-105">
                    <img src="https://via.placeholder.com/400x300.png?text=Sangeet+Night" alt="Sangeet Night" className="w-full h-48 object-cover"/>
                    <div className="p-6">
                        <h3 className="text-2xl font-semibold text-gray-800 mb-2">Sangeet Night</h3>
                        <p className="text-gray-600">An evening filled with music, dance, and performances celebrating the couple.</p>
                    </div>
                </div>
            </div>

            <div className="ceremony-card group">
                <div className="bg-white rounded-xl shadow-xl overflow-hidden transform transition-all duration-300 group-hover:scale-105">
                    <img src="https://via.placeholder.com/400x300.png?text=Baraat+Coordination" alt="Baraat Coordination" className="w-full h-48 object-cover"/>
                    <div className="p-6">
                        <h3 className="text-2xl font-semibold text-gray-800 mb-2">Baraat Coordination</h3>
                        <p className="text-gray-600">Coordinating the groom's grand entrance with traditional fanfare and flair.</p>
                    </div>
                </div>
            </div>

            <div className="ceremony-card group">
                <div className="bg-white rounded-xl shadow-xl overflow-hidden transform transition-all duration-300 group-hover:scale-105">
                    <img src="https://via.placeholder.com/400x300.png?text=Pheras+Ritual" alt="Pheras Ritual" className="w-full h-48 object-cover"/>
                    <div className="p-6">
                        <h3 className="text-2xl font-semibold text-gray-800 mb-2">Pheras Ritual</h3>
                        <p className="text-gray-600">The sacred rounds around the holy fire, symbolizing the couple's vows.</p>
                    </div>
                </div>
            </div>

            <div className="ceremony-card group">
                <div className="bg-white rounded-xl shadow-xl overflow-hidden transform transition-all duration-300 group-hover:scale-105">
                    <img src="https://via.placeholder.com/400x300.png?text=Vidaai" alt="Vidaai" className="w-full h-48 object-cover"/>
                    <div className="p-6">
                        <h3 className="text-2xl font-semibold text-gray-800 mb-2">Vidaai</h3>
                        <p className="text-gray-600">A tearful farewell as the bride leaves her family to start a new life.</p>
                    </div>
                </div>
            </div>

            <div className="ceremony-card group">
                <div className="bg-white rounded-xl shadow-xl overflow-hidden transform transition-all duration-300 group-hover:scale-105">
                    <img src="https://via.placeholder.com/400x300.png?text=Kanyadaan" alt="Kanyadaan" className="w-full h-48 object-cover"/>
                    <div className="p-6">
                        <h3 className="text-2xl font-semibold text-gray-800 mb-2">Kanyadaan</h3>
                        <p className="text-gray-600">The bride's father gives her away in this emotional and traditional ceremony.</p>
                    </div>
                </div>
            </div>

            <div className="ceremony-card group">
                <div className="bg-white rounded-xl shadow-xl overflow-hidden transform transition-all duration-300 group-hover:scale-105">
                    <img src="https://via.placeholder.com/400x300.png?text=Reception" alt="Reception" className="w-full h-48 object-cover"/>
                    <div className="p-6">
                        <h3 className="text-2xl font-semibold text-gray-800 mb-2">Reception</h3>
                        <p className="text-gray-600">A grand celebration that marks the official union of the couple.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="text-center mt-12">
        <button href="#contact2" className="bg-white text-red-600 hover:bg-gray-100 font-bold py-3 px-8 rounded-full transition duration-300 ease-in-out transform hover:scale-105">
            Explore Our Wedding Packages
        </button>
    </div>
</section>
  )
}
export default Culture;