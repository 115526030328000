import React from 'react'


const OurProcess = () => {
    return ( <section className="bg-white rounded-2xl shadow-2xl p-8 mb-12">
    <h2 className="text-4xl font-bold text-center mb-8 text-gray-800 font-serif">Our Process</h2>
    <div className="flex flex-col md:flex-row justify-between items-center space-y-8 md:space-y-0 md:space-x-8">
        <div className="bg-gradient-to-r from-rose-300 via-red-400 to-pink-500 rounded-xl shadow-lg p-6 text-center text-white w-full md:w-1/4">
            <h3 className="font-bold text-xl mb-2">1. Consultation</h3>
            <p>We start with an in-depth consultation to understand your vision and preferences.</p>
        </div>
        <div className="bg-gradient-to-r from-amber-300 via-yellow-400 to-orange-500 rounded-xl shadow-lg p-6 text-center text-white w-full md:w-1/4">
            <h3 className="font-bold text-xl mb-2">2. Planning</h3>
            <p>Our team creates a detailed plan, including timeline, budget, and vendor selection.</p>
        </div>
        <div className="bg-gradient-to-r from-lime-300 via-green-400 to-teal-500 rounded-xl shadow-lg p-6 text-center text-white w-full md:w-1/4">
            <h3 className="font-bold text-xl mb-2">3. Execution</h3>
            <p>We manage all aspects of your wedding day, ensuring everything runs smoothly.</p>
        </div>
        <div className="bg-gradient-to-r from-cyan-300 via-blue-400 to-indigo-500 rounded-xl shadow-lg p-6 text-center text-white w-full md:w-1/4">
            <h3 className="font-bold text-xl mb-2">4. Celebration</h3>
            <p>You enjoy your perfect day while we handle all the behind-the-scenes details.</p>
        </div>
    </div>
</section> );
}

export default OurProcess;