import React from 'react';

const Concierege = () => {
    return (

        <section className="wedding-concierge-section bg-gradient-to-br from-purple-600 to-pink-500 rounded-3xl shadow-2xl p-12 mb-12 overflow-hidden relative">
    <div className="absolute top-0 left-0 w-full h-full bg-white opacity-5">
        <svg className="w-full h-full" viewBox="0 0 100 100" preserveAspectRatio="none">
            <path d="M0,0 L100,0 L100,100 L0,100 Z" fill="none" stroke="white" stroke-width="0.5"/>
            <path d="M0,100 L100,0" stroke="white" stroke-width="0.5"/>
            <path d="M0,0 L100,100" stroke="white" stroke-width="0.5"/>
        </svg>
    </div>

    <div className="relative z-10">
        <h2 className="text-5xl font-extrabold text-center mb-12 text-white tracking-wide">
            Personal Wedding Concierge
        </h2>

        <div className="flex flex-col lg:flex-row items-center justify-between space-y-12 lg:space-y-0 lg:space-x-12">
            <div className="w-full lg:w-1/2 flex justify-center">
                <div className="relative">
                    <div className="absolute inset-0 bg-gradient-to-r from-yellow-400 to-pink-500 rounded-full blur-xl opacity-70 animate-pulse"></div>
                    <div className="relative bg-white rounded-full p-8 shadow-xl">
                        <img src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png" alt="Concierge Icon" className="w-48 h-48 transition-transform transform hover:scale-110 animate-float"/>
                    </div>
                </div>
            </div>

            <div className="w-full lg:w-1/2 text-white space-y-6">
                <h3 className="text-3xl font-bold mb-4">Relax, We've Got It Covered</h3>
                <p className="text-xl leading-relaxed">
                    Every couple is assigned a dedicated wedding planner, ready to handle every aspect of your big day. From venue selection to managing last-minute changes, we're here to ensure everything runs smoothly.
                </p>
                <ul className="space-y-3">
                    <li className="flex items-center">
                        <svg className="w-6 h-6 mr-2 text-yellow-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                        Personalized guidance from the start
                    </li>
                    <li className="flex items-center">
                        <svg className="w-6 h-6 mr-2 text-yellow-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                        Quick answers to all your questions
                    </li>
                    <li className="flex items-center">
                        <svg className="w-6 h-6 mr-2 text-yellow-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                        Managing last-minute changes seamlessly
                    </li>
                    <li className="flex items-center">
                        <svg className="w-6 h-6 mr-2 text-yellow-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                        Ensuring a stress-free planning experience
                    </li>
                </ul>
                <p className="text-xl italic">
                    Your wedding planner is here to make sure you feel cared for, from the moment you contact us until the last guest leaves.
                </p>
            </div>
        </div>
    </div>
</section>
     );
}

export default Concierege;