import React from 'react'
const Religion = () => {
    return (
        <section className="bg-white rounded-2xl shadow-2xl p-8 mb-12">
    <h2 className="text-4xl font-bold text-center mb-8 text-gray-800 font-serif">Our Cultural Expertise</h2>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        <div className="bg-gray-50 rounded-xl p-6 shadow-lg transition duration-300 ease-in-out transform hover:scale-105">
            <span className="text-4xl mb-4 block">🕉️</span>
            <h3 className="text-2xl font-bold mb-2">Hindu Weddings</h3>
            <p className="text-gray-600">Expert planning for all Hindu wedding rituals and customs.</p>
        </div>
        <div className="bg-gray-50 rounded-xl p-6 shadow-lg transition duration-300 ease-in-out transform hover:scale-105">
            <span className="text-4xl mb-4 block">☪️</span>
            <h3 className="text-2xl font-bold mb-2">Muslim Weddings</h3>
            <p className="text-gray-600">Specialized in Nikah ceremonies and Islamic wedding traditions.</p>
        </div>
        <div className="bg-gray-50 rounded-xl p-6 shadow-lg transition duration-300 ease-in-out transform hover:scale-105">
            <span className="text-4xl mb-4 block">☬</span>
            <h3 className="text-2xl font-bold mb-2">Sikh Weddings</h3>
            <p className="text-gray-600">Proficient in organizing Anand Karaj and related Sikh customs.</p>
        </div>
        <div className="bg-gray-50 rounded-xl p-6 shadow-lg transition duration-300 ease-in-out transform hover:scale-105">
            <span className="text-4xl mb-4 block">🕊️</span>
            <h3 className="text-2xl font-bold mb-2">Fusion Weddings</h3>
            <p className="text-gray-600">Expertise in blending different cultural traditions seamlessly.</p>
        </div>
    </div>
    <div className="mt-12 text-center">
        <p className="text-lg text-gray-600 mb-6">Our team's deep understanding of various Indian cultures ensures that every wedding we plan is authentic, respectful, and truly special.</p>
        <button href="#contact2"  className="bg-gold hover:bg-gold-light text-white font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105">
            Discuss Your Cultural Needs
        </button>
    </div>
</section>
     );
}

export default Religion;