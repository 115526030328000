import React, { useState, useEffect } from 'react';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    countryCode: '+91',
    phone: '',
    weddingDate: '',
    service: '',
    message: '',
    acceptTerms: true // Default checked
  });

  const [notification, setNotification] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.acceptTerms) {
      setNotification({
        type: 'error',
        message: 'Please accept the terms and conditions to proceed.'
      });
      return;
    }
    try {
      // Add the form data to Firestore
      const docRef = await addDoc(collection(db, 'inquiries'), formData);
      console.log("Document written with ID: ", docRef.id);

      // Clear the form after successful submission
      setFormData({
        name: '',
        email: '',
        countryCode: '+91',
        phone: '',
        weddingDate: '',
        service: '',
        message: '',
        acceptTerms: true
      });

      // Show success notification
      setNotification({
        type: 'success',
        message: 'Thank you for your inquiry! Our team will connect with you shortly. You will receive a notification on WhatsApp.'
      });
    } catch (error) {
      console.error("Error adding document: ", error);
      // Show error notification
      setNotification({
        type: 'error',
        message: 'There was an error submitting your inquiry. Please try again.'
      });
    }
  };

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification(null);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [notification]);

  return (
    <section id="contact" className="bg-white rounded-2xl shadow-2xl p-8 mb-12 relative">
      <h2 className="text-5xl font-serif font-bold text-center mb-12 text-gray-800">
        Plan Your Dream Wedding
      </h2>
      <form className="max-w-lg mx-auto space-y-4" onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-gold focus:border-transparent"
          required
          value={formData.name}
          onChange={handleChange}
        />
        <input
          type="email"
          name="email"
          placeholder="Email Address"
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-gold focus:border-transparent"
          required
          value={formData.email}
          onChange={handleChange}
        />
        <div>
          <div className="flex">
            <PhoneInput
              defaultCountry="in"
              placeholder="Enter phone number"
              value={formData.phone}
              onChange={(phone) => setFormData(prev => ({ ...prev, phone }))}
              inputStyle={{
                width: '100%',
                height: '50px',
                fontSize: '16px',
                borderRadius: '0 0.375rem 0.375rem 0',
                border: '1px solid #D1D5DB',
                borderLeft: 'none',
                outline: 'none',
                paddingLeft: '8px',
              }}
              containerStyle={{
                width: '100%',
              }}
              buttonStyle={{
                border: '1px solid #D1D5DB',
                borderRight: 'none',
                borderRadius: '0.375rem 0 0 0.375rem',
                background: 'white',
              }}
              className="w-full focus:ring-2 focus:ring-gold focus:border-gold"
            />
          </div>
        </div>
        <input
          type="date"
          name="weddingDate"
          placeholder="Wedding Date"
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-gold focus:border-transparent"
          required
          value={formData.weddingDate}
          onChange={handleChange}
        />
        <select
          name="service"
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-gold focus:border-transparent"
          required
          value={formData.service}
          onChange={handleChange}
        >
          <option value="">Select Preferred Service</option>
          <option value="full-planning">Full Wedding Planning</option>
          <option value="partial-planning">Partial Planning</option>
          <option value="day-of-coordination">Day-of Coordination</option>
        </select>
        <textarea
          name="message"
          placeholder="Tell us about your dream wedding"
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-gold focus:border-transparent"
          rows="4"
          required
          value={formData.message}
          onChange={handleChange}
        ></textarea>
        <div className="flex items-center">
          <input
            type="checkbox"
            id="acceptTerms"
            name="acceptTerms"
            checked={formData.acceptTerms}
            onChange={handleChange}
            className="mr-2"
          />
          <label htmlFor="acceptTerms" className="text-sm text-gray-700">
            I accept the terms and conditions
          </label>
        </div>
        <button
          type="submit"
          className="w-full bg-gold hover:bg-gold-light text-white font-bold py-3 px-6 rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
        >
          Start Planning
        </button>
      </form>

      {notification && (
        <div className={`fixed bottom-4 right-4 max-w-md p-4 rounded-lg shadow-lg transition-all duration-500 ease-in-out ${
          notification.type === 'success' ? 'bg-green-500' : 'bg-red-500'
        }`}>
          <p className="text-white">{notification.message}</p>
        </div>
      )}
    </section>
  );
};

export default ContactForm;