import React from 'react';


const Expertise = () => {


  return (
    <section className="bg-white p-16 mb-12 rounded-3xl shadow-lg">
            <h2 className="text-5xl font-serif font-bold text-center mb-12 text-gray-800">Our Expertise</h2>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
                <div className="expertise-card group">
                    <div className="flex items-start space-x-6">
                        <div className="expertise-icon bg-gray-100 text-gray-600 rounded-full p-4 transition-colors duration-300 group-hover:bg-gray-200">
                            <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4 2a2 2 0 00-2 2v11a3 3 0 106 0V4a2 2 0 00-2-2H4zm1 14a1 1 0 100-2 1 1 0 000 2zm5-1.757l4.9-4.9a2 2 0 000-2.828L13.485 5.1a2 2 0 00-2.828 0L10 5.757v8.486zM16 18H9.071l6-6H16a2 2 0 012 2v2a2 2 0 01-2 2z" clip-rule="evenodd"></path></svg>
                        </div>
                        <div>
                            <h3 className="text-2xl font-semibold mb-2 text-gray-800">Traditional Decor</h3>
                            <p className="text-gray-600">Intricate mandaps, vibrant floral arrangements, and ornate decor that reflect Indian culture.</p>
                        </div>
                    </div>
                </div>

                <div className="expertise-card group">
                    <div className="flex items-start space-x-6">
                        <div className="expertise-icon bg-gray-100 text-gray-600 rounded-full p-4 transition-colors duration-300 group-hover:bg-gray-200">
                            <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd"></path></svg>
                        </div>
                        <div>
                            <h3 className="text-2xl font-semibold mb-2 text-gray-800">Memorable Photography</h3>
                            <p className="text-gray-600">Capture the beauty and emotions of your wedding day with our expert photographers.</p>
                        </div>
                    </div>
                </div>

                <div className="expertise-card group">
                    <div className="flex items-start space-x-6">
                        <div className="expertise-icon bg-gray-100 text-gray-600 rounded-full p-4 transition-colors duration-300 group-hover:bg-gray-200">
                            <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path><path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clip-rule="evenodd"></path></svg>
                        </div>
                        <div>
                            <h3 className="text-2xl font-semibold mb-2 text-gray-800">Personalized Planning</h3>
                            <p className="text-gray-600">Tailored planning services that align with your vision, style, and budget.</p>
                        </div>
                    </div>
                </div>

                <div className="expertise-card group">
                    <div className="flex items-start space-x-6">
                        <div className="expertise-icon bg-gray-100 text-gray-600 rounded-full p-4 transition-colors duration-300 group-hover:bg-gray-200">
                            <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path></svg>
                        </div>
                        <div>
                            <h3 className="text-2xl font-semibold mb-2 text-gray-800">Vendor Management</h3>
                            <p className="text-gray-600">We handle all vendors, from caterers to musicians, ensuring your day is stress-free.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default Expertise;
