import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyA8tBjyop0pcNvRWMzW428UlSdyA8X3Cxw",
  authDomain: "vivid-86757.firebaseapp.com",
  projectId: "vivid-86757",
  storageBucket: "vivid-86757.appspot.com",
  messagingSenderId: "602167124046",
  appId: "1:602167124046:web:c7ef9c970d9f6cc618a31f",
  measurementId: "G-S96YEV4BRE"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };