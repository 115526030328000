import React from 'react';
import headerImage from '../assets/weeding-header2.jpg';

const Header = () => {
  return (
    <header
    className="relative h-screen bg-cover bg-center"
    style={{ backgroundImage: `url(${headerImage})` }} // Use template literal correctly
  >
<div className="absolute inset-0 bg-gradient-to-b from-maroon/70 to-gold/70 flex flex-col items-center justify-center text-center px-4">
        <div className="max-w-4xl mx-auto">
            <h1 className="text-5xl md:text-7xl font-bold text-white font-serif mb-6 leading-tight">
                Vivid Wedding
            </h1>
            <p className="text-xl md:text-2xl text-white mb-8 font-light">
                Crafting Unforgettable Moments for Your Special Day
            </p>
            <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
                <button href="#contact" className="bg-white text-maroon hover:bg-gold hover:text-white font-bold py-3 px-8 rounded-full transition duration-300 ease-in-out transform hover:scale-105">
                    Plan Your Wedding
                </button>
                <a href="#services" className="text-white hover:text-gold font-semibold py-3 px-8 border border-white hover:border-gold rounded-full transition duration-300 ease-in-out">
                    Our Services
                </a>
            </div>
        </div>
    </div>
    <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/50 to-transparent">
        <nav className="flex justify-center space-x-6">
            <a href="#home" className="text-white hover:text-gold transition duration-300">Home</a>
            <a href="#services" className="text-white hover:text-gold transition duration-300">Services</a>
            <a href="#contact" className="text-white hover:text-gold transition duration-300">Counsult Now</a>
        </nav>
    </div>
</header>

  );
};

export default Header;