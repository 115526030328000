import React from 'react';


const ServicesSection = () => {


  return (
    <section id="services" className="bg-gray-50 py-20 px-8">
    <div className="max-w-6xl mx-auto">
        <h2 className="text-5xl font-serif font-bold text-center mb-16 text-gray-800">Our Services</h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <div className="service-card bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl flex flex-col h-full">
                <div className="p-8 flex-grow">
                    <div className="w-16 h-16 bg-pink-100 rounded-full flex items-center justify-center mb-6 mx-auto">
                        <svg className="w-8 h-8 text-pink-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path>
                        </svg>
                    </div>
                    <h3 className="text-2xl font-semibold mb-4 text-center text-gray-800">Full Wedding Planning</h3>
                    <p className="text-gray-600 text-center">Comprehensive planning and coordination from start to finish, ensuring every detail is perfect.</p>
                </div>
                <div className="bg-pink-50 p-4 mt-auto">
                    <a href="#" className="text-pink-600 hover:text-pink-800 font-semibold text-center block">Learn More</a>
                </div>
            </div>

            <div className="service-card bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl flex flex-col h-full">
                <div className="p-8 flex-grow">
                    <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mb-6 mx-auto">
                        <svg className="w-8 h-8 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                    </div>
                    <h3 className="text-2xl font-semibold mb-4 text-center text-gray-800">Partial Planning</h3>
                    <p className="text-gray-600 text-center">Assistance with specific aspects of your wedding, tailored to your needs and preferences.</p>
                </div>
                <div className="bg-blue-50 p-4 mt-auto">
                    <a href="#" className="text-blue-600 hover:text-blue-800 font-semibold text-center block">Learn More</a>
                </div>
            </div>

            <div className="service-card bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl flex flex-col h-full">
                <div className="p-8 flex-grow">
                    <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mb-6 mx-auto">
                        <svg className="w-8 h-8 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"></path>
                        </svg>
                    </div>
                    <h3 className="text-2xl font-semibold mb-4 text-center text-gray-800">Day-of Coordination</h3>
                    <p className="text-gray-600 text-center">Expert management of your wedding day, ensuring a smooth and stress-free experience.</p>
                </div>
                <div className="bg-green-50 p-4 mt-auto">
                    <a href="#" className="text-green-600 hover:text-green-800 font-semibold text-center block">Learn More</a>
                </div>
            </div>
        </div>
    </div>
</section>
  );
};

export default ServicesSection;