import React from 'react';

const FamilyFirst = () => {
    return (

        <section className="bg-gradient-to-r from-maroon to-royal-blue rounded-2xl shadow-2xl p-8 mb-12 text-white">
    <h2 className="text-4xl font-bold text-center mb-8 font-serif">Family First: Your Guests, Our Priority</h2>
    <p className="text-lg text-center mb-12">
        We treat your guests like family, ensuring they have a seamless and memorable experience throughout your special day. From accommodation to personalized itineraries, we handle every detail with care and respect.
    </p>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="bg-white text-gray-800 rounded-xl p-6 shadow-lg transition duration-300 ease-in-out transform hover:scale-105">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 mx-auto text-gold mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>
            <h3 className="text-2xl font-bold mb-4 text-center">Guest Experience Management</h3>
            <ul className="space-y-2">
                <li className="flex items-center">
                    <svg className="h-5 w-5 text-gold mr-2" fill="currentColor" viewBox="0 0 20 20">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                    </svg>
                    <span><strong>Accommodation Coordination:</strong> We manage hotel bookings, transport, and special requests.</span>
                </li>
                <li className="flex items-center">
                    <svg className="h-5 w-5 text-gold mr-2" fill="currentColor" viewBox="0 0 20 20">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                    </svg>
                    <span><strong>Personalized Itineraries:</strong> Custom schedules for guests, including activities and transportation.</span>
                </li>
            </ul>
        </div>
        <div className="bg-white text-gray-800 rounded-xl p-6 shadow-lg transition duration-300 ease-in-out transform hover:scale-105">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 mx-auto text-gold mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
            </svg>
            <h3 className="text-2xl font-bold mb-4 text-center">Family Involvement</h3>
            <ul className="space-y-2">
                <li className="flex items-center">
                    <svg className="h-5 w-5 text-gold mr-2" fill="currentColor" viewBox="0 0 20 20">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                    </svg>
                    <span><strong>Cultural Sensitivity:</strong> Respecting and incorporating family traditions and rituals.</span>
                </li>
                <li className="flex items-center">
                    <svg className="h-5 w-5 text-gold mr-2" fill="currentColor" viewBox="0 0 20 20">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                    </svg>
                    <span><strong>Special Requests:</strong> Accommodating unique family needs and traditions.</span>
                </li>
            </ul>
        </div>
        <div className="bg-white text-gray-800 rounded-xl p-6 shadow-lg transition duration-300 ease-in-out transform hover:scale-105">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 mx-auto text-gold mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
            </svg>
            <h3 className="text-2xl font-bold mb-4 text-center">Guest Comfort</h3>
            <ul className="space-y-2">
                <li className="flex items-center">
                    <svg className="h-5 w-5 text-gold mr-2" fill="currentColor" viewBox="0 0 20 20">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                    </svg>
                    <span><strong>On-the-Day Support:</strong> Ensuring all guests are well looked after and any concerns are addressed.</span>
                </li>
            </ul>
        </div>
    </div>
    <div className="text-center mt-12">
        <button href="#contact"  className="bg-white text-maroon hover:bg-gray-100 font-bold py-3 px-8 rounded-full transition duration-300 ease-in-out transform hover:scale-105">
            Get in Touch
        </button>
    </div>
</section>
     );
}

export default FamilyFirst;