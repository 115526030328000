import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import ContactForm from './components/ContactForm';
import ServicesSection from './components/ServicesSection';
import Expertise from './components/Expertise';
import CompleteCare from './components/CompleteCare';
import FamilyFirst from './components/FamilyFirst';
import Concierege from './components/Concierege';
import Journey from './components/Journey';
import Culture from './components/Culture';
import WhyToChoose from './components/WhyToChoose';
import OurProcess from './components/OurProcess';
import Religion from './components/Religion';
import Feedback from './components/Feedback';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import useSmoothScroll from './utils/useSmoothScroll';
import ContactForm2 from './components/ContactForm2';
import { Helmet } from 'react-helmet';

function App() {
  useSmoothScroll();
  return (
    <Router>
      <div className="App" >
      <Helmet>
      <title>Elegant Indian Weddings | Luxury Wedding Planner</title>
      <meta name="description" content="Plan your dream Indian wedding with our luxury wedding planning services. Customized packages for all your wedding needs." />
      <meta name="keywords" content="Indian wedding, wedding planner, luxury wedding, wedding services" />
      <link rel="canonical" href="https://vividwedding.in/" />
    </Helmet>
    <Helmet>
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "WeddingPlanner",
        "name": "Vivid Indian Weddings",
        "description": "Luxury Indian wedding planning services",
        "url": "https://vividwedding.in/",
        }
      }
    `}
  </script>
</Helmet>
        <body className="bg-pattern font-sans text-gray-800">
          <Header />
          <main className="container mx-auto px-4 py-12">

            <ContactForm />
            <ServicesSection />
            <Expertise />
            <CompleteCare />
            <FamilyFirst />
            <Concierege />
            <Journey />
            <Culture />
            <WhyToChoose />
            <OurProcess />
            <Religion />
            <Feedback />
            <ContactForm />
            <FAQ />

          </main>
          <Footer />
        </body>
      </div>
    </Router>
  );
}

export default App;